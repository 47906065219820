import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login($userName: String!, $password: String!) {
    classMethods {
      User {
        login(userName: $userName, password: $password) {
          success
          requireRadius
          authLocation
          targetLocation
        }
      }
    }
  }
`;

export const getCurrentUserQuery = gql`
  query getCurrentUser {
    classMethods {
      User {
        getCurrentUser {
          id
          userName
          disabled
          email
          firstName
          lastName
          deviceLimit
          mobileNumber
          mobileCountry
          overLimit
          requireAcceptTNC
          requireChangePassword
          addresses {
            total
            edges {
              node {
                id
                streetNo
                address
                suburb
                state
                postCode
                unitNo
                countryId
                country {
                  id
                  name
                }
              }
            }
          }
          role {
            id
            name
          }
          # activePlan: getActivePlan {
          #   id
          #   usage
          #   unlimited
          #   total
          #   start
          #   end
          # }
          # activeRenewal: getActiveOrder {
          #   id
          #   renewalPeriod
          #   renewsAt
          #   status
          #   products {
          #     edges {
          #       node {
          #         id
          #         name
          #         value
          #         description
          #         rateProfile
          #         bandwidth
          #       }
          #     }
          #   }
          # }
          devices {
            edges {
              node {
                id
                macAddress
                description
                disabled
                clientId
                ip4
                ip6
                leaseExpiry
                hostname
                manufacturer
                isActive
              }
            }
          }

          groups {
            edges {
              node {
                id
                name
                active
                code
              }
            }
          }
          activeGroup: getActiveGroup {
            id
            name
            membershipEnd
            membershipStart
          }
          hasUserTOTP
        }
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($userId: ID, $input: UserOptionalInput!) {
    models {
      User(update: {
        where: {
          id: {eq: $userId}
        }
        limit: 1
        input: $input
      }) {
        id
      }
    }
  }
`;

export const setPasswordMutation = gql`
  mutation setPassword ($id: ID!, $password: String!, $oldPassword: String!) {
    classMethods {
      UserAuth {
        setPassword (id: $id, password: $password, oldPassword: $oldPassword)
      }
    }
  }
`;

export const purchaseMutation = gql`
  mutation changeOrderMutation(
    $ip: String
    $userId: ID!
    $ccard: CreditCardObject
    $voucherCode: String
    $products: [String]
    $schedule: Boolean,
    ) {
    classMethods {
      User {
        changeUserOrder(
          ip: $ip
          userId: $userId
          ccard: $ccard
          voucherCode: $voucherCode
          products: $products
          schedule: $schedule,
          ) {
          id
          userName
        }
      }
    }
  }
`;

export function getPurchaseResult({data}) {
  return data?.classMethods?.User?.changeUserOrder;
}

export const logoutMutation = gql`
  mutation {
    classMethods {
      User {
        logout
      }
    }
  }
`;

export const cancelOrderMutation = gql`
  mutation cancelOrder ($userId: ID!, $orderId: ID!) {
    classMethods {
      Order {
        cancelOrder (userId: $userId, orderId: $orderId)
      }
    }
  }
`;

export function getCancelOrderResult({data}) {
  return data?.classMethods?.Order?.cancelOrder;
}

export const acceptTNCMutation = gql`
  mutation acceptTnc ($password: String!, $oldPassword: String!) {
    classMethods {
      User {
        acceptTnc (password: $password, oldPassword: $oldPassword)
      }
    }
  }
`;

export function getAcceptTNCMutationResult({data}) {
  return data?.classMethods?.User?.acceptTnc;
}


export const getUserTOTP = gql`
query getUserAuth($userId: ID){
  models {
    UserAuth(where: {
      userId: {
        eq: $userId
      },
      type: {
        eq: "user-totp"
      }
    }) {
      edges {
        node {
          id
          type
        }
      }
    }
  }
}
` 